<template>
  <div class="trivia-container d-flex justify-content-center">
    <siteAlert
      :id="'newUserModal'"
      ref="modal"
      :btnText="modalText"
      :exitPropFunction="setNextQuestion"
    />
    <div class="trivia-inner">
      <div
        class="trivia-play"
        v-if="!isLoading && questionNumber < numOfQuestions"
      >
        <trivia-question
          :info="results[questionNumber]"
          ref="triviaQuestion"
          @clicked="optionSelected"
        />
        <siteButton
          v-on:click="nextQuestionBtn"
          :isLoading="isDisabled"
          :text="'Próxima questão'"
          class=""
          :isDisabled="!isSelected"
        />
        <div class="question-counter">
          <span>
            {{ questionNumber }} pergunta(s) respondida(s) de
            {{ numOfQuestions }}</span
          >
          <div class="progress">
            <div
              class="progress-bar progress-bar-striped progress-bar-animated"
              role="progressbar"
              :aria-valuenow="questionNumber + 1"
              aria-valuemin="0"
              :aria-valuemax="numOfQuestions"
              :style="'width:' + (questionNumber / numOfQuestions) * 100 + '%'"
            ></div>
          </div>
        </div>
      </div>
      <div class="trivia-end" v-if="questionNumber == numOfQuestions">
        <h1>Você acertou {{ (countRight / numOfQuestions) * 100 }}% {{ endEmoji }}</h1>
        <p>{{ endText }} </p>
        
          <siteButton
          v-on:click="this.$router.push('/')"
          :isLoading="isDisabled"
          :text="'Jogar Novamente'"
          class=""
        />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import TriviaQuestion from "@/components/trivia/trivia-question.vue";
import siteAlert from "@/components/main/site-alert.vue";
import siteButton from "@/components/main/site-button.vue";

export default {
  name: "HomeView",
  components: {
    siteButton,
    TriviaQuestion,
    siteAlert,
  },
  data() {
    return {
      headers: [],
      results: [],
      isLoading: true,
      message: "",
      modalText: "",
      questionNumber: 0,
      numOfQuestions: 10,
      isSelected: false,
      countRight: 0,
      endText: "",
      endEmoji: "",
      percentualResponses: [
        {
          percentage: 0,
          emoji: "🫠",
          text: "A vereda do justo é como a luz da aurora, que brilha cada vez mais até ser dia perfeito. - PV 4:18 ",
        },
        {
          percentage: 25,
          emoji: "🥲",
          text: "Não to mandei eu? Sê forte e corajoso; não temas, nem te espantes, porque o SENHOR, teu Deus, é contigo por onde quer que andares. - Josué 1:9 ",
        },
        {
          percentage: 50,
          emoji: "😅",
          text: "Por isso, não abram mão da confiança que vocês têm; ela será ricamente recompensada. - Hebreus 10:35",
        },
        {
          percentage: 75,
          emoji: "🫡",
          text: "Ensina-nos a contar os nossos dias para que o nosso coração alcance sabedoria. - Salmos 90:12",
        },
        {
          percentage: 100,
          emoji: "😁",
          text: "Encheste o meu coração de alegria, alegria maior do que a daqueles que têm fartura de trigo e de vinho. - Salmos 4:7",
        },
      ],
    };
  },
  methods: {
    setNextQuestion() {
      this.questionNumber++;
      if(this.questionNumber >= this.numOfQuestions){
        this.setResponse()
      }
    },
    nextQuestionBtn() {
      if (this.questionNumber + 1 < this.numOfQuestions) {
        this.modalText = "Próxima Questão";
      } else {
        this.modalText = "Finalizar";
      }
      this.$refs.triviaQuestion.unsetOptions();
      const currentOptions = this.results[this.questionNumber].options;
      const rightAnswer =
        currentOptions[currentOptions.findIndex((el) => el.isRight == true)];
      const option = this.$refs.triviaQuestion.getSelectedOption();
      if (!option.isRight) {
        this.$refs.modal.showModal("error", [
          "A resposta certa é :",
          rightAnswer.letter + ") " + rightAnswer.text,
        ]);
      }
      if (option.isRight) {
        this.countRight++;
        console.log(this.countRight);
        this.$refs.modal.showModal("success", [
          "Parabéns! Você acertou! ",
          rightAnswer.letter + ") " + rightAnswer.text,
        ]);
      }
    },
    optionSelected(val) {
      this.isSelected = val;
    },
    async getTable() {
      this.isLoading = true;

      var id = "1mCzLHIxwO4eMhngLm-Ieg7lN_f2Y-hnCxgzpgvY0nzA";
      var gid = "0";
      var url =
        "https://docs.google.com/spreadsheets/d/" +
        id +
        "/gviz/tq?tqx=out:json&tq&gid=" +
        gid;

      let results = [];

      await axios
        .get(url)
        .then((response) => {
          var jsTable = JSON.parse(
            response.data.substring(47).slice(0, -2)
          ).table;

          jsTable.cols.forEach((col) => {
            if (col.label.length > 0) this.headers.push(col.label);
          });

          this.headers.length == 0;

          for (let i in jsTable.rows) {
            let row = jsTable.rows[i];
            if (this.headers.length == 0 && i == 0) {
              row.c.forEach((el) => this.headers.push(el.v));
            } else {
              let rowData = {};
              for (let i in this.headers) {
                if (row.c[i]) rowData[this.headers[i]] = row.c[i].v;
                else rowData[this.headers[i]] = null;
              }
              results.push(rowData);
            }
          }
        })
        .catch((e) => {
          console.log(e);
          this.message = e.message;
        });

      results = this.shuffle(results);

      if (results.length < this.numOfQuestions) {
        this.numOfQuestions = results.length;
      } else if (results.length > this.numOfQuestions) {
        results = results.slice(0, this.numOfQuestions);
      }

      this.normalize(results);
      this.isLoading = false;
    },
    normalize(results) {
      results.forEach((result) => {
        let temp = {
          title: result.title,
          text: result.text,
          options: [
            { text: result.letterA },
            { text: result.letterB },
            { text: result.letterC },
            { text: result.letterD },
          ],
        };
        temp.options[0].isRight = true;
        temp.options = this.shuffle(temp.options);
        for (let i in temp.options) {
          temp.options[i].letter = String.fromCharCode(65 + Number(i));
        }
        this.results.push(temp);
      });
      for (let i in this.results) {
        this.results[i].num = Number(i) + 1;
      }
    },
    setResponse() {
      let percentage = (this.countRight / this.numOfQuestions) * 100;
      for (let i in this.percentualResponses) {
        const response = this.percentualResponses[i];
        const nextResponse = this.percentualResponses[i + 1];
        if (percentage >= response.percentage) {
          if (nextResponse) {
            if (percentage < nextResponse.percentage) {
              this.endEmoji = response.emoji;
              this.endText = response.text
            }
          }else{            
            this.endEmoji = response.emoji;
              this.endText = response.text
          }
        }
      }
    },
    shuffle(array) {
      let currentIndex = array.length;

      // While there remain elements to shuffle...
      while (currentIndex != 0) {
        // Pick a remaining element...
        let randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex],
          array[currentIndex],
        ];
      }
      return array;
    },
  },
  async mounted() {
    await this.getTable();
  },
};
</script>
