<template>
  <div>
    <div
      class="modal fade"
      :id="id"
      tabindex="-1"
      :aria-labelledby="id + 'Label'"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <h5 class="modal-title" :id="id + 'Label'">
              {{ type == `error` ? `Errado! 😔` : `Correto! 😀` }}
            </h5>
            <p v-for="(txt, index) of text" v-bind:key="index">{{ txt }}</p>
          </div>
          <div class="modal-footer">
            <siteButton
              type="button"
              class="col-6 col-md-6 col-lg-4"
              :text="btnText"
              data-bs-dismiss="modal"
              v-on:Click="exitFunction()"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bootstrap from "bootstrap/dist/js/bootstrap";
import siteButton from "@/components/main/site-button.vue";

export default {
  components: { siteButton },
  name: "siteAlert",
  props: { id: String, exitPropFunction: Object, btnText: String },
  data() {
    return {
      text: "unset error message",
      type: "error",
      myModal: {},
      backdrop: {},
    };
  },
  methods: {
    showModal(type, text) {
      if (type) this.type = type;
      if (text) this.text = text;

      if (!this.myModal._isShown) this.myModal.toggle();
    },
    exitFunction() {
      if (this.exitPropFunction) this.exitPropFunction();
    },
  },
  mounted() {
    this.myModal = new bootstrap.Modal(document.getElementById(this.id));
    if (this.myModal._isShown) this.myModal.hide();
  },
};
</script>