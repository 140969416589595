<template>
  <div class="loading justify-content-center h-100">
    <div class="row d-flex justify-content-center align-items-center">
      <div class="spinner-border" role="status"></div>
    </div>
    <div class="row d-flex text-center">
      <span v-if="isText" class="sr-only">Carregando...</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "siteSpinner",
  props: {
    text: String,
    isText: {
            type: Boolean,
            default: true
        },
  },
  data() {
    return {};
  },
  mounted() {
    // alert('Vue Main Component mounted');
  },
};
</script>