<template>
  <div class="trivia-container d-flex justify-content-center">
    <div class="trivia-inner">
      <h2 class="trivia-title">
        <span class="trivia-num">{{ info.num }}.</span> {{ info.title }}
      </h2>
      <p class="trivia-text">
        {{ info.text }}
      </p>

      <trivia-option
        v-for="(option, index) of info.options"
        v-bind:key="index"
        :option="{ letter: option.letter, text: option.text }"
        v-on:Click="select(option)"
      />
      <!-- 
        :onClick="select(option)" -->
      <!-- <trivia-option :option="{ letter: 'A', text: 'bar' }" /> -->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import TriviaOption from "@/components/trivia/trivia-option.vue";

export default {
  name: "HomeView",
  components: {
    TriviaOption,
  },
  props: {
    info: Object,
  },
  data() {
    return {
      selectedOption: {},
    };
  },
  methods: {
    select(option) {
      this.selectedOption = option;
      // console.log(this.selectedOption);
this.unsetOptions()
      document
        .getElementById(option.letter + "-option")
        .classList.add("selected");

      this.$emit("clicked", true);
    },
    unsetOptions() {
      let options = document.getElementsByClassName("question-option");

      for (let el of options) el.classList.remove("selected");
      
      this.$emit("clicked", false);
    },
    getSelectedOption() {
      return this.selectedOption.letter ? this.selectedOption : false;
    },
  },
  mounted() {
    console.log(this.info);
  },
};
</script>
