<template>
  <button
    type="button"
    :class="classes"
    v-on:click="onClickFunction"
    :disabled="isDisabled || isLoading"
    :id="idProp"
  >
    {{ text }}

    <siteSpinner :isText="false" v-if="loading" />
  </button>
</template>

<script>
import siteSpinner from "@/components/main/site-spinner.vue";
export default {
  components: { siteSpinner },
  name: "siteButton",
  props: {
    idProp: String,
    text: String,
    onClickFunction: Function,
    isDisabled: Boolean,
    isLoading: Boolean,
    classList: String,
  },
  data() {
    return {
      classes: "btn",
    };
  },
  methods: {},
  mounted() {
    if (this.classList) this.classes += " " + this.classList;
    else {
      this.classes += " btn-primary";
    }
  },
};
</script>