import { createRouter, createWebHistory } from "vue-router";
// import HomeView from "../views/HomeView.vue";
import TriviaView from "../views/TriviaView.vue";

const routes = [
  
  // {
  //   path: "/",
  //   name: "home",
  //   component: HomeView,
  // },
  {
    path: "/",
    name: "trivia",
    component: TriviaView,
  },
  // {
  //   path: "/trivia",
  //   name: "trivia",
  //   component: TriviaView,
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
