<template>
  <div :id="option.letter+'-option'" class="question-option">
    <span
      ><span  class="option-letter">{{ option.letter }})</span>
      {{ option.text }}</span
    >
  </div>
</template>

<script>
export default {
  name: "triviaOption",
  props: {
    option: Object,
  },
  data() {
    return {};
  },
  mounted() {
  },
};
</script>